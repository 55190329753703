body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 0.8em;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

td {
    text-align: left;
}

.euro {
    text-align: right;
}

.euro::before {
    content: "€";
}

.total {
    font-weight: bold;
    margin-bottom: 2em !important;
}

.table-striped>tbody>tr.spacer>* {
    --bs-table-accent-bg: transparent;
}
